import React, { lazy, Suspense, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ProfileCheckRoute from './components/routes/ProfileCheckRoute';
import Add from './components/site/Add';
import Header from './components/site/Header';
import Footer from './components/site/Footer';
import api from './data/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iosShare from './images/ios-share.png';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  fab,
  faFacebook,
  faTwitter,
  faLinkedin,
  faFacebookSquare,
} from '@fortawesome/free-brands-svg-icons';
import {
  fas,
  faCheckSquare,
  faCoffee,
  faPlus,
  faThumbsUp,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  fal,
  faLightbulb,
  faBolt,
  faUser,
  faUsers,
  faShareSquare,
  faCloudUpload,
  faEllipsisV,
  faLightbulbOn,
  faCloudDownload,
  faFlag,
  faNewspaper,
  faPager,
  faListAlt,
  faSignOutAlt,
  faAngleDown,
  faTrashAlt,
  faBell,
  faPen,
  faRetweet,
} from '@fortawesome/pro-light-svg-icons';
import { faSpinner, faUserEdit } from '@fortawesome/pro-duotone-svg-icons';
import { useSelector } from 'react-redux';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import Joyride from 'react-joyride';
import { useToasts } from 'react-toast-notifications';
import Callback from './components/authentication/Callback';

// import AddToHomeScreen from './components/helpers/homescreen/AddToHomeScreen';

library.add(
  fab,
  fal,
  fas,
  faCheckSquare,
  faCoffee,
  faFacebook,
  faTwitter,
  faLinkedin,
  faLightbulb,
  faBolt,
  faUser,
  faUsers,
  faPlus,
  faThumbsUp,
  faShareSquare,
  faCloudUpload,
  faEllipsisV,
  faLightbulbOn,
  faCircle,
  faCloudDownload,
  faFacebookSquare,
  faSpinner,
  faFlag,
  faNewspaper,
  faPager,
  faListAlt,
  faSignOutAlt,
  faUserEdit,
  faAngleDown,
  faTrashAlt,
  faBell,
  faPen,
  faRetweet
);

const Home = lazy(() => import('./pages/Home' /* webpackChunkName: "Home" */));
const ForYouFeed = lazy(() =>
  import('./pages/ForYouFeed' /* webpackChunkName: "ForYouFeed" */)
);
const FollowingFeed = lazy(() =>
  import('./pages/FollowingFeed' /* webpackChunkName: "FollowingFeed" */)
);
const About = lazy(() =>
  import('./pages/About' /* webpackChunkName: "About" */)
);
const ScavengerHunt = lazy(() =>
  import('./pages/ScavengerHunt' /* webpackChunkName: "ScavengerHunt" */)
);
const MemberStories = lazy(() =>
  import('./pages/MemberStories' /* webpackChunkName: "MemberStories" */)
);
const Contact = lazy(() =>
  import('./pages/Contact' /* webpackChunkName: "Contact" */)
);
const BrowserButton = lazy(() =>
  import('./pages/BrowserButton' /* webpackChunkName: "BrowserButton" */)
);
const Badges = lazy(() =>
  import('./pages/Badges' /* webpackChunkName: "Badgesn" */)
);
const PrivacyPolicy = lazy(() =>
  import('./pages/PrivacyPolicy' /* webpackChunkName: "PrivacyPolicy" */)
);
const Authentication = lazy(() =>
  import('./pages/Authentication' /* webpackChunkName: "Authentication" */)
);
const SignUp = lazy(() =>
  import('./pages/SignUp' /* webpackChunkName: "SignUp" */)
);
const EditProfile = lazy(() =>
  import('./pages/EditProfile' /* webpackChunkName: "EditProfile" */)
);
const DeleteProfile = lazy(() =>
  import('./pages/DeleteProfile' /* webpackChunkName: "DeleteProfile" */)
);
const Profile = lazy(() =>
  import('./pages/Profile' /* webpackChunkName: "Profile" */)
);
const CollectionAdd = lazy(() =>
  import('./pages/CollectionAdd' /* webpackChunkName: "CollectionAdd" */)
);
const CollectionEdit = lazy(() =>
  import('./pages/CollectionEdit' /* webpackChunkName: "CollectionEdit" */)
);
const Collection = lazy(() =>
  import('./pages/Collection' /* webpackChunkName: "Collection" */)
);
const CollectionOrganize = lazy(() =>
  import(
    './pages/CollectionOrganize' /* webpackChunkName: "CollectionOrganize" */
  )
);
const Following = lazy(() =>
  import('./pages/Following' /* webpackChunkName: "Following" */)
);
const PostAdd = lazy(() =>
  import('./pages/PostAdd' /* webpackChunkName: "PostAdd" */)
);
const PostEdit = lazy(() =>
  import('./pages/PostEdit' /* webpackChunkName: "PostEdit" */)
);
const Post = lazy(() => import('./pages/Post' /* webpackChunkName: "Post" */));
const Search = lazy(() =>
  import('./pages/Search' /* webpackChunkName: "Search" */)
);
const OfflineHome = lazy(() =>
  import('./pages/OfflineHome' /* webpackChunkName: "OfflineHome" */)
);
const OfflinePost = lazy(() =>
  import('./pages/OfflinePost' /* webpackChunkName: "OfflinePost" */)
);
const Intro = lazy(() =>
  import('./pages/Intro' /* webpackChunkName: "Intro" */)
);
const Terms = lazy(() =>
  import('./pages/Terms' /* webpackChunkName: "Terms" */)
);
const Guidelines = lazy(() =>
  import('./pages/Guidelines' /* webpackChunkName: "Guidelines" */)
);
const Browse = lazy(() =>
  import('./pages/Browse' /* webpackChunkName: "BrowseUsers" */)
);
const Settings = lazy(() =>
  import('./pages/Settings' /* webpackChunkName: "Settings" */)
);
const HomescreenInstructions = lazy(() =>
  import(
    './pages/HomescreenInstructions' /* webpackChunkName: "HomescreenInstructions" */
  )
);
const Trailblazer = lazy(() =>
  import('./pages/Trailblazer' /* webpackChunkName: "Trailblazer" */)
);
const BrowseCollections = lazy(() =>
  import(
    './pages/BrowseCollections' /* webpackChunkName: "BrowseCollections" */
  )
);
const CancelCollectionInvite = lazy(() =>
  import(
    './pages/CancelCollectionInvite' /* webpackChunkName: "CancelCollectionInvite" */
  )
);
const DeclineOwnershipTransfer = lazy(() =>
  import(
    './pages/DeclineOwnershipTransfer' /* webpackChunkName: "DeclineOwnershipTransfer" */
  )
);

const UnsubscribePinpoint = lazy(() =>
  import('./pages/UnsubscribePinpoint' /* webpackChunkName: "Unsubscribe" */)
);

const App = () => {
  const user = useSelector((state) => state.user.user);
  const isOnline = useSelector((state) => state.online.isOnline);
  const a11yMessage = useSelector((state) => state.user.a11yMessage);
  const [showInstallMessage, setShowInstallMessage] = useState(false);
  const { addToast } = useToasts();
  const currentLanguage = useSelector((state) => state.user.currentLanguage);
  const [forYouFeedLabel, setForYouFeedLabel] = useState('For You Feed');
  const [forYouContent, setForYouContent] = useState(
    'Shows you content that is personalized to your own interests.'
  );
  const [tourTitle, setTourTitle] = useState('Welcome to FP insight!');
  const [tourContent, setTourContent] = useState(
    'Click Next to take a quick tour of FP insight.'
  );
  const [trendingFeedLabel, setTrendingFeedLabel] = useState('Trending Feed');
  const [trendingFeedContent, setTrendingFeedContent] = useState(
    'Shows you content that’s popular across all FP insight users.'
  );
  const [followingFeedLabel, setFollowingFeedLabel] =
    useState('Following Feed');
  const [followingFeedContent, setFollowingFeedContent] = useState(
    'Shows you recently added content from the people and collections that you’re following.'
  );
  const [postAddLabel, setPostAddLabel] = useState('Add a post!');
  const [postAddContent, setPostAddContent] = useState(
    'Add a post to FP insight, so you can easily come back to it when you need it! All posts must be added to a collection. Think of collections like folders, and posts are like the files inside of the folders.'
  );
  const [profileLabel, setProfileLabel] = useState('Your Profile');
  const [profileContent, setProfileContent] = useState(
    'Access your saved posts and collections in your profile.'
  );
  const [badgesLabel, setBadgesLabel] = useState('Badges');
  const [badgesContent, setBadgesContent] = useState(
    'By joining FP insight you’ve already received a gold Member badge. To earn your blue Explorer badge, complete our interactive'
  );
  const [scavengerLabel, setScavengerLabel] = useState('scavenger hunt!');
  const [helpLabel, sethelpLabel] = useState('Need more help?');
  const [helpContent, setHelpContent] = useState(
    'Get quick answers through our Virtual HelpDesk.'
  );
  const [skipLabel, setSkipLabel] = useState('Skip');
  const [backLabel, setBackLabel] = useState('Back');
  const [nextLabel, setnextLabel] = useState('Next');
  const [closeLabel, setCloseLabel] = useState('Close');
  const [lastLabel, setLastLabel] = useState('Last');
  const [showJoyride, setShowJoyride] = useState(false);
  const [hideFooter, setHideFooter] = useState(false);

  React.useEffect(() => {
    if (window.location.href.includes('SignUp')) {
      console.log('hide the footer');
      setShowJoyride(false);
      setHideFooter(false);
    } else {
      setHideFooter(false);
    }
  }, [window.location.href]);

  React.useEffect(() => {
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    const isInStandaloneMode = () =>
      'standalone' in window.navigator && window.navigator.standalone;

    if (isIos() && !isInStandaloneMode()) {
      setShowInstallMessage(true);
    }
  }, []);

  React.useEffect(() => {
    translateLabels();
  }, [currentLanguage]);

  React.useEffect(() => {
    // console.log('user changed');
    if (user) {
      // console.log(
      //   `tour setting: ${user.initialTourCompleted} path: ${window.location.pathname}`
      // );
      if (!user.initialTourCompleted || user.initialTourCompleted === false) {
        // only show the joyride if we're on the home page
        if (window.location.pathname === '/') {
          console.log('show joyride');
          toggleNavBar();
          setShowJoyride(true);
          //window.location.reload();
        } else if (window.location.href.includes('login=false')) {
          console.log('hide joyride');
          setShowJoyride(false);
        }
      }
    }
  }, [user]);

  const translateLabels = async (language) => {
    try {
      if (!language || language === 'en') {
        setForYouFeedLabel('For You Feed');
        setTourTitle('Welcome to FP insight!');
        setTourContent('Click Next to take a quick tour of FP insight.');
        setForYouContent(
          'Shows you content that is personalized to your own interests.'
        );
        setTrendingFeedLabel('Trending Feed');
        setTrendingFeedContent(
          'Shows you content that’s popular across all FP insight users.'
        );
        setFollowingFeedLabel('Following Feed');
        setFollowingFeedContent(
          'Shows you recently added content from the people and collections that you’re following.'
        );
        setPostAddLabel('Add a post!');
        setPostAddContent(
          'Add a post to FP insight, so you can easily come back to it when you need it! All posts must be added to a collection. Think of collections like folders, and posts are like the files inside of the folders.'
        );
        setProfileLabel('Your Profile');
        setProfileContent(
          'Access your saved posts and collections in your profile.'
        );
        setBadgesLabel('Badges');
        setBadgesContent(
          'By joining FP insight you’ve already received a gold Member badge. To earn your blue Explorer badge, complete our interactive'
        );
        setScavengerLabel('scavenger hunt!');
        sethelpLabel('Need more help?');
        setHelpContent('Get quick answers through our Virtual HelpDesk.');
        setSkipLabel('Skip');
        setBackLabel('Back');
        setnextLabel('Next');
        setCloseLabel('Close');
        setLastLabel('Last');

        return;
      } else {
        const sections = await api.post(
          `${process.env.REACT_APP_API_BASE}/admin/translateText`,
          {
            currentLanguage: currentLanguage,
            text: [
              forYouFeedLabel,
              tourTitle,
              tourContent,
              forYouContent,
              trendingFeedLabel,
              trendingFeedContent,
              followingFeedLabel,
              followingFeedContent,
              postAddLabel,
              postAddContent,
              profileLabel,
              profileContent,
              badgesLabel,
              badgesContent,
              scavengerLabel,
              helpLabel,
              helpContent,
              skipLabel,
              backLabel,
              nextLabel,
              closeLabel,
              lastLabel,
            ],
            type: 'text',
            objectType: 'array',
          }
        );

        if (sections.data && sections.data.length === 22) {
          setForYouFeedLabel(sections.data[0]);
          setTourTitle(sections.data[1]);
          setTourContent(sections.data[2]);
          setForYouContent(sections.data[3]);
          setTrendingFeedLabel(sections.data[4]);
          setTrendingFeedContent(sections.data[5]);
          setFollowingFeedLabel(sections.data[6]);
          setFollowingFeedContent(sections.data[7]);
          setPostAddLabel(sections.data[8]);
          setPostAddContent(sections.data[9]);
          setProfileLabel(sections.data[10]);
          setProfileContent(sections.data[11]);
          setBadgesLabel(sections.data[12]);
          setBadgesContent(sections.data[13]);
          setScavengerLabel(sections.data[14]);
          sethelpLabel(sections.data[15]);
          setHelpContent(sections.data[16]);
          setSkipLabel(sections.data[17]);
          setBackLabel(sections.data[18]);
          setnextLabel(sections.data[19]);
          setCloseLabel(sections.data[20]);
          setLastLabel(sections.data[21]);
        }
      }
    } catch {
      addToast(`There was an error translating`, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 4000,
      });
    }
  };

  const steps = [
    {
      content: <span>{tourContent}</span>,
      placement: 'center',
      target: 'body',
      title: tourTitle,
    },
    {
      content: <span>{forYouContent}</span>,
      floaterProps: {
        disableAnimation: true,
      },
      target: '.btn-for-you',
      title: forYouFeedLabel,
    },
    {
      content: <span>{trendingFeedContent}</span>,
      placement: 'bottom',
      styles: {
        options: {
          width: 300,
        },
      },
      target: '.btn-trending',
      title: trendingFeedLabel,
    },
    {
      content: <span>{followingFeedContent}</span>,
      placement: 'top',
      target: '.btn-following',
      title: followingFeedLabel,
    },
    {
      content: <div>{postAddContent}</div>,
      placement: 'right',
      target: '.tour-add-post',
      title: postAddLabel,
    },
    {
      content: <div>{profileContent}</div>,
      placement: 'auto',
      target: '.mobile-avatar',
      title: profileLabel,
    },
    {
      content: (
        <div>
          {badgesContent} <a href="scavengerhunt"> {scavengerLabel}</a>
        </div>
      ),
      placement: 'left',
      target: '.explorer',
      title: badgesLabel,
    },
    {
      content: <div>{helpContent}</div>,
      placement: 'left',
      target: '.bubble-question',
      title: helpLabel,
    },
  ];

  const toggleNavBar = () => {
    let nav = document.getElementsByClassName('navbar-collapse')[0];
    nav.classList.toggle('show');
  };

  const handleJoyrideCallback = async (data) => {
    const { type, index } = data;
    const badgesIndex = 5;
    if (index > badgesIndex && type === 'step:before') {
      console.log('scroll up so the badges are available');
      window.scrollTo(0, 0);
    }
    if (index > badgesIndex + 1 && type === 'step:before') {
      console.log('scroll up so the question mark is available');
      window.scrollTo(0, 0);
    }
    if (data.type === 'tour:end') {
      // update that the user has finished the tour
      await api.post(
        `${process.env.REACT_APP_API_BASE}/user/setInitialTourComplete`,
        { userid: user._id }
      );
      toggleNavBar();
      console.log(`capture last step ${data.type} ${data.index}`);
    }
  };

  return isOnline ? (
    <div>
      {/* <AddToHomeScreen appId="FP-insight-app" displayPace={0} /> */}
      <Router>
        <Header />

        <Add />
        <Joyride
          callback={handleJoyrideCallback}
          continuous={true}
          run={showJoyride}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          steps={steps}
          locale={{
            back: backLabel,
            close: closeLabel,
            last: closeLabel,
            next: nextLabel,
            skip: skipLabel,
          }}
          styles={{
            options: {
              arrowColor: '#fff',
              backgroundColor: '#fff',
              beaconSize: 36,
              overlayColor: 'rgba(0, 0, 0, 0.5)',
              primaryColor: '#F3B120',
              spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
              textColor: '#000',
              width: undefined,
              zIndex: 1000,
            },
          }}
        />
        <Suspense
          fallback={
            <div className="text-center">
              Loading...{' '}
              <div role="alert" className="sr-only" aria-live="polite">
                Page Loading
              </div>{' '}
            </div>
          }
        >
          <Switch>
            <ProfileCheckRoute
              path="/collectionAdd"
              component={CollectionAdd}
            />
            <ProfileCheckRoute
              path="/collectionEdit/:id"
              component={CollectionEdit}
            />
            <ProfileCheckRoute
              path="/collection/:id/:section"
              component={Collection}
            />
            <ProfileCheckRoute path="/collection/:id" component={Collection} />
            <ProfileCheckRoute
              path="/collectionOrganize/:id/:section"
              component={CollectionOrganize}
            />
            <ProfileCheckRoute
              path="/collectionOrganize/:id"
              component={CollectionOrganize}
            />
            <Route path="/auth">
              <Authentication />
            </Route>
            <Route
              path="/deleteprofile"
              exact={true}
              component={DeleteProfile}
            />
            <Route path="/signup" exact={true} component={SignUp} />
            <Route path="/profile" exact={true} component={EditProfile} />
            <ProfileCheckRoute path="/profile/:id" component={Profile} />
            <ProfileCheckRoute path="/following/:id" component={Following} />
            <ProfileCheckRoute path="/postAdd/:id" component={PostAdd} />
            <ProfileCheckRoute path="/postAdd" component={PostAdd} />
            <ProfileCheckRoute path="/postEdit/:id" component={PostEdit} />
            <ProfileCheckRoute path="/post/:id" component={Post} />
            <ProfileCheckRoute path="/search" component={Search} />
            <ProfileCheckRoute path="/callback" component={Callback} />
            <ProfileCheckRoute path="/settings" component={Settings} />
            <Route path="/about">
              <About />
            </Route>
            <Route path="/scavengerhunt">
              <ScavengerHunt />
            </Route>
            <Route path="/memberstories">
              <MemberStories />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/browser-button">
              <BrowserButton />
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/badges">
              <Badges />
            </Route>
            <Route path="/intro">
              <Intro />
            </Route>
            <Route path="/terms">
              <Terms />
            </Route>
            <Route path="/guidelines">
              <Guidelines />
            </Route>
            <Route path="/browse" component={Browse} />
            <Route path="/browsecollections" component={BrowseCollections} />
            <Route
              path="/CancelCollectionInvite"
              component={CancelCollectionInvite}
            />
            <Route
              path="/DeclineOwnership"
              component={DeclineOwnershipTransfer}
            />
            <Route path="/Unsubscribe" component={UnsubscribePinpoint} />
            <ProfileCheckRoute path="/foryou" component={ForYouFeed} />
            <ProfileCheckRoute
              path="/followingFeed"
              component={FollowingFeed}
            />
            <Route path="/homescreen-instructions">
              <HomescreenInstructions />
            </Route>
            <Route path="/trailblazer">
              <Trailblazer />
            </Route>
            <ProfileCheckRoute path="/" component={Home} />
          </Switch>
          <AmplifyAuthenticator
            style={{ display: 'none' }}
            usernameAlias="email"
          ></AmplifyAuthenticator>
          <div
            className="sr-only"
            role="status"
            aria-live="polite"
            aria-atomic="true"
          >
            {a11yMessage}
          </div>
        </Suspense>
        {!hideFooter && <Footer />}
        {showInstallMessage && (
          <div className="ios-message-wrapper">
            <div className="ios-safari-message d-flex align-items-center">
              <div>
                <FontAwesomeIcon
                  icon={['fas', 'plus-square']}
                  className="fa-2x"
                />
              </div>{' '}
              <div className="ios-text">
                To install FP insight on your device, tap the{' '}
                <img
                  src={iosShare}
                  alt="FP Insights, powered by Knowledge Success"
                  className="ios-share-icon"
                />{' '}
                in the menu below and then “Add to Home Screen”.
              </div>
            </div>
          </div>
        )}
      </Router>
    </div>
  ) : (
    <div>
      <Router>
        <Header />
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path="/post/:id" component={OfflinePost} />
            <Route path="/" component={OfflineHome} />
          </Switch>
        </Suspense>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
