const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';
const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
const RESET_SEARCH = 'RESET_SEARCH';
const SET_SEARCHING = 'SET_SEARCHING';
const SET_RUN_SEARCH = 'SET_RUN_SEARCH';

const setSearchTerm = term => ({
  type: SET_SEARCH_TERM,
  payload: term
});

const setSearchType = type => ({
  type: SET_SEARCH_TYPE,
  payload: type
});

const setSearchResults = results => ({
  type: SET_SEARCH_RESULTS,
  payload: results
});

const resetSearch = () => ({
  type: RESET_SEARCH
})

const setSearching = searching => ({
  type: SET_SEARCHING,
  payload: searching
});

const setRunSearch = runSearch => ({
  type: SET_RUN_SEARCH,
  payload: runSearch
})

const exports = {
  SET_SEARCH_TERM,
  SET_SEARCH_TYPE,
  SET_SEARCH_RESULTS,
  RESET_SEARCH,
  SET_SEARCHING,
  SET_RUN_SEARCH,
  setSearchTerm,
  setSearchType,
  setSearchResults,
  resetSearch,
  setSearching,
  setRunSearch
}

export default exports;