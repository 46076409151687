import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Add = () => {
  const user = useSelector(state => state.user.user);
  const auth = useSelector(state => state.authenticated.auth);
  const location = useLocation();

  return (
    <div >
        {
            user && auth && !(location.pathname.startsWith('/postAdd') || location.pathname.startsWith('/Profile') || location.pathname.startsWith('/collectionEdit') || location.pathname.startsWith('/collectionAdd')|| location.pathname.startsWith('/SignUp'))
            ?
                <div className="bubble-float">
                    <Link to="/postAdd" aria-label="Add a post" className="addPost tour-add-post bubble-add d-flex justify-content-center align-items-center"><FontAwesomeIcon icon={['fas', 'plus']} size="2x" />
                    </Link>
                    <a href="https://kmhelpdesk.knowledgesuccess.org/fp-insight-virtual-helpdesk" target="_blank" rel="noreferrer" aria-label="Help, opens in new window" className="addPostQuestion bubble-question d-flex justify-content-center align-items-center"><FontAwesomeIcon icon={['fas', 'question']} size="2x" />
                    </a>
                </div>
            :
                null
        }
    </div>
  )
}

export default Add;