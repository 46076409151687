import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProfileCheckRoute = ({component: Component, ...rest}) => {
  const user = useSelector(state => state.user.user);

  return (
    <Route
      {...rest}
      render={(props) => user && !user.country
        ? <Redirect to={{pathname: '/SignUp', state: { from: props.location }}} />
        : <Component {...props} />}
    />
  )
}

export default ProfileCheckRoute;