const SET_USER = "SET_USER";
const MERGE_USER = "MERGE_USER";
const ADD_USER_COLLECTION = 'ADD_USER_COLLECTION';
const UPDATE_USER_COLLECTION = 'UPDATE_USER_COLLECTION';
const SET_MY_COLLECTION = 'SET_MY_COLLECTION';
const SHOW_A11Y_MESSAGE = 'SHOW_A11Y_MESSAGE';
const FOLLOW_COLLECTION = 'FOLLOW_COLLECTION';
const UNFOLLOW_COLLECTION = 'UNFOLLOW_COLLECTION';
const FOLLOW_USER = 'FOLLOW_USER';
const UNFOLLOW_USER = 'UNFOLLOW_USER';
const SET_LANGUAGE = "SET_LANGUAGE";

const setUser = user => ({
  type: SET_USER,
  payload: {
    user: user
  }
})

const mergeUser = user => ({
  type: MERGE_USER,
  payload: {
    user: user
  }
})

const addCollection = collection => ({
  type: ADD_USER_COLLECTION,
  payload: {
    collection: collection
  }
})

const updateCollection = collection => ({
  type: UPDATE_USER_COLLECTION,
  payload: {
    collection: collection
  }
})

const setMyCollection = collectionid => ({
  type: SET_MY_COLLECTION,
  payload: collectionid
})

const a11yMessage = message => ({
  type: SHOW_A11Y_MESSAGE,
  payload: message
})

const followCollection = collectionId => ({
  type: FOLLOW_COLLECTION,
  payload: collectionId
})

const unfollowCollection = collectionId => ({
  type: UNFOLLOW_COLLECTION,
  payload: collectionId
})

const followUser = userId => ({
  type: FOLLOW_USER,
  payload: userId
})

const unfollowUser = userId => ({
  type: UNFOLLOW_USER,
  payload: userId
})

const setLanguage = lang => ({
  type: SET_LANGUAGE,
  payload: lang
})

const exports = {
  SET_USER,
  MERGE_USER,
  ADD_USER_COLLECTION,
  UPDATE_USER_COLLECTION,
  SET_MY_COLLECTION,
  SHOW_A11Y_MESSAGE,
  FOLLOW_COLLECTION,
  UNFOLLOW_COLLECTION,
  FOLLOW_USER,
  UNFOLLOW_USER,
  SET_LANGUAGE,
  setUser,
  mergeUser,
  addCollection,
  updateCollection,
  setMyCollection,
  a11yMessage,
  followCollection,
  unfollowCollection,
  followUser,
  unfollowUser,
  setLanguage
}

export default exports;