import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import api from '../../data/api';
import USAIDLogo from '../../images/usaid-logo.svg';
import kslogo from '../../images/knowledge-success-logo.svg';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useToasts } from 'react-toast-notifications';

const Footer = () => {
  const currentLanguage = useSelector((state) => state.user.currentLanguage);
  const hash = process.env.REACT_APP_RELEASE_HASH;
  const [footerText, setFooterText] = useState(
    '<div className="row"> <div className="col-lg-12"><p>This website is made possible by the support of the American People through the United States Agency for International Development (USAID) under the Knowledge SUCCESS (Strengthening Use, Capacity, Collaboration, Exchange, Synthesis, and Sharing) Project. Knowledge SUCCESS is supported by USAID’s Bureau for Global Health, Office of Population and Reproductive Health and led by the Johns Hopkins Center for Communication Programs (CCP) in partnership with Amref Health Africa, The Busara Center for Behavioral Economics (Busara), and FHI 360. The contents of this website are the sole responsibility of the individual users who post the content. The information provided on this website is not official United States Government information and does not necessarily reflect the views or positions of USAID, the United States Government, or The Johns Hopkins University.</p> </div>  </div>'
  );
  const [aboutLinkLabel, setAboutLinkLabel] = useState('About');
  const [contactLinkLabel, setContactLinkLabel] = useState('Contact');
  const [helpLinkLabel, setHelpLinkLabel] = useState('Help');
  const [termsLinkLabel, setTermsLinkLabel] = useState('Terms of Use');
  const [guidelinesLinkLabel, setGuidelinesLinkLabel] = useState(
    'Community Guidelines'
  );
  const [privacyLinkLabel, setPrivacyLinkLabel] = useState('Privacy Policy');
  const [copyright, setCopyright] = useState(
    '© Copyright 2024 - Johns Hopkins University, All rights reserved.'
  );
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  React.useEffect(() => {
    async function fetchData() {
      const footerText =
        '<div className="row"> <div className="col-lg-12"><p>This website is made possible by the support of the American People through the United States Agency for International Development (USAID) under the Knowledge SUCCESS (Strengthening Use, Capacity, Collaboration, Exchange, Synthesis, and Sharing) Project. Knowledge SUCCESS is supported by USAID’s Bureau for Global Health, Office of Population and Reproductive Health and led by the Johns Hopkins Center for Communication Programs (CCP) in partnership with Amref Health Africa, The Busara Center for Behavioral Economics (Busara), and FHI 360. The contents of this website are the sole responsibility of the individual users who post the content. The information provided on this website is not official United States Government information and does not necessarily reflect the views or positions of USAID, the United States Government, or The Johns Hopkins University.</p> </div>  </div>';
      const aboutLinkLabel = 'About';
      const contactLinkLabel = 'Contact';
      const helpLinkLabel = 'Help';
      const termsLinkLabel = 'Terms of Use';
      const guidelinesLinkLabel = 'Community Guidelines';
      const privacyLinkLabel = 'Privacy Policy';
      const copyRightText =
        '© Copyright 2024 - Johns Hopkins University, All rights reserved.';

      if (currentLanguage !== 'en') {
        try {
          setLoading(true);

          // get all sections in one call
          const sections = await api.post(
            `${process.env.REACT_APP_API_BASE}/admin/translateText`,
            {
              currentLanguage: currentLanguage,
              text: [
                aboutLinkLabel,
                helpLinkLabel,
                termsLinkLabel,
                guidelinesLinkLabel,
                privacyLinkLabel,
                copyRightText,
                contactLinkLabel,
              ],
              type: 'text',
              objectType: 'array',
            }
          );

          if (sections.data && sections.data.length === 7) {
            setAboutLinkLabel(sections.data[0]);
            setHelpLinkLabel(sections.data[1]);
            setTermsLinkLabel(sections.data[2]);
            setGuidelinesLinkLabel(sections.data[3]);
            setPrivacyLinkLabel(sections.data[4]);
            setCopyright(sections.data[5]);
            setContactLinkLabel(sections.data[6]);
          }

          const footerHtmlText = await api.post(
            `${process.env.REACT_APP_API_BASE}/admin/translateText`,
            { currentLanguage: currentLanguage, text: footerText, type: 'html' }
          );
          setFooterText(footerHtmlText.data);
        } catch {
          addToast(`There was an error translating the footer text`, {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 10000,
          });
        } finally {
          setLoading(false);
        }
      } else {
        setFooterText(footerText);
        setAboutLinkLabel(aboutLinkLabel);
        setContactLinkLabel(contactLinkLabel);
        setHelpLinkLabel(helpLinkLabel);
        setTermsLinkLabel(termsLinkLabel);
        setGuidelinesLinkLabel(guidelinesLinkLabel);
        setPrivacyLinkLabel(privacyLinkLabel);
        setCopyright(copyRightText);
      }
    }

    fetchData();
  }, [currentLanguage]);

  return (
    <div className="footer">
      {loading ? (
        <div className="col-lg-12 text-center mt-3 mb-3">
          <FontAwesomeIcon
            icon={['fad', 'spinner']}
            size="2x"
            color="#00709B"
            spin
          />
        </div>
      ) : (
        <footer aria-label="site footer" role="contentinfo" className="">
          <div className="container">
            <div className="row pt-3">
              <div className="col-sm-6 col-lg-3 text-center">
                <img
                  className="USAIDLogo img-fluid"
                  src={USAIDLogo}
                  alt="USAID"
                />
                <img
                  className="kslogo img-fluid"
                  src={kslogo}
                  alt="Knowledge Success"
                />
              </div>
              <div className="col-sm-12 col-lg-9 mt-4 footer-text">
                {parse(footerText)}
                <ul className="footer-links pt-3">
                  <li>
                    <Link className="" to={'/about'}>
                      {aboutLinkLabel} FP insight
                    </Link>
                  </li>
                  <li>
                    <Link className="" to={'/contact'}>
                      {contactLinkLabel} FP insight
                    </Link>
                  </li>
                  <li>
                    <a
                      href="https://kmhelpdesk.knowledgesuccess.org/fp-insight-virtual-helpdesk"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {helpLinkLabel}
                    </a>
                  </li>
                </ul>
                <ul className="footer-links pb-5">
                  <li>
                    <Link className="" to={'/terms'}>
                      {termsLinkLabel}
                    </Link>
                  </li>
                  <li>
                    <Link className="" to={'/guidelines'}>
                      {guidelinesLinkLabel}
                    </Link>
                  </li>
                  <li>
                    <Link className="" to={'/privacy-policy'}>
                      {privacyLinkLabel}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row disclaimer d-flex align-items-center justify-content-center">
              <div className="col-12-lg text-center">{parse(copyright)} </div>
            </div>
          </div>
          {window.location.href.indexOf('staging.fpinsight.org') > -1 ? (
            <div className="container-fluid">[{hash}]</div>
          ) : (
            <div className="container-fluid" hidden>
              [{hash}]
            </div>
          )}
        </footer>
      )}
    </div>
  );
};

export default Footer;
