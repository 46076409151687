import onlineActions from '../actions/online';
import resetActions from '../actions/reset';

const initialState = {
  isOnline: true,
  offlinePosts: []
}

const onlineReducer = (state = initialState, action) => {
  switch(action.type) {
    case onlineActions.SET_ONLINE:
      return { ...state, isOnline: action.payload };
    case onlineActions.SET_POSTS:
      return { ...state, offlinePosts: action.payload };
    case resetActions.RESET_ALL:
      return initialState;
    default:
      return state;
  }
}

export default onlineReducer;