const RESET_ALL = "RESET_ALL";

const resetAll = () => ({
  type: RESET_ALL
})

const exports = {
  RESET_ALL,
  resetAll
};

export default exports;