const SET_ONLINE = 'SET_ONLINE';
const SET_POSTS = 'SET_POSTS';

const setOnline = (isOnline) => ({
  type: SET_ONLINE,
  payload: isOnline
})

const setPosts = (posts) => ({
  type: SET_POSTS,
  payload: posts
})

const exports = {
  SET_ONLINE,
  SET_POSTS,
  setOnline,
  setPosts
};

export default exports;