import React from 'react';

function useLanguageHistory(initialValue) {
  const [allStates, setState] = React.useReducer((oldState, newState) => {
    return [...oldState, newState];
  },
  typeof initialValue !== undefined
    ? [initialValue]
    : ""
  )
  const currentState = allStates[allStates?.length - 1];
  const stateHistory = allStates?.slice(0, allStates?.length - 1);
  return [currentState, setState, stateHistory];
}

export default useLanguageHistory;
