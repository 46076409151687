import searchActions from '../actions/search';
import resetActions from '../actions/reset';

const initialState = {
  term: '',
  type: 'posts',
  results: null,
  searching: false,
  countries: null,
  runSearch: false
}

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case searchActions.SET_SEARCH_TERM:
      const term = action.payload;
      return {
        ...state,
        term: term
      }
    case searchActions.SET_SEARCH_TYPE:
      const type = action.payload;
      return {
        ...state,
        type: type
      }
    case searchActions.SET_SEARCH_RESULTS:
      const results = action.payload;
      return {
        ...state,
        results: results
      }
    
    case searchActions.SET_COUNTRIES:
      const countries = action.payload;
      return {
        ...state,
        countries: countries
      }

    case searchActions.SET_SEARCHING:
      const searching = action.payload;
      return {
        ...state,
        searching: searching
      }
    case searchActions.SET_RUN_SEARCH:
      const runSearch = action.payload;
      return {
        ...state,
        runSearch: runSearch
      }
    case searchActions.RESET_SEARCH:
      return initialState;
    case resetActions.RESET_ALL:
      return initialState;
    default:
        return state;
  }
}

export default searchReducer;