import React from 'react';
import NonGenderAvatar from '../../images/user-solid.svg';

const Avatar = ({ user, onClick, isIndividualPost }) => {
  const getUserPhotoSource = () => {
    if (user.photo) {
      return user.photo;
    } else {
      return NonGenderAvatar;
    }
  };
  return (
    <span
      className={`${isIndividualPost ? 'individual-post-avatar' : 'avatar'}`}
      onClick={onClick}
    >
      <img
        className={`${
          isIndividualPost ? 'individual-post-avatar-image' : 'avatar-image'
        }`}
        alt=""
        src={getUserPhotoSource()}
      />
    </span>
  );
};

export default Avatar;
