import React from 'react';
import './custom.scss'; 
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { PersistGate } from 'redux-persist/integration/react'
//import reportWebVitals from './reportWebVitals';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';

import axios from 'axios';

import registerOfflineListener from './offline-listener';
import TagManager from 'react-gtm-module'

import { ToastProvider } from 'react-toast-notifications';
 
const tagManagerArgs = {
    gtmId: 'GTM-WD3H94W',
    events: {
      UserRegistration: 'User Registration',
      UserSignIn: 'User Sign-In',
      UserDeletion: 'User Deletion'
    }
}
 
TagManager.initialize(tagManagerArgs)

Amplify.configure({...awsconfig, Auth: {
  region: 'us-east-1',
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
}});

const oauth = {
  domain: 'jhccp.auth.us-east-1.amazoncognito.com',
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: process.env.REACT_APP_FEDERATED_LOG_IN,
  redirectSignOut: process.env.REACT_APP_FEDERATED_LOG_OUT,
  responseType: 'token' 
};

Auth.configure({ oauth })

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Accept'] = 'application/json';

registerOfflineListener();

const A11yToast = ({ appearance, children }) => (
  <div role="alert" className="toast-wrapper" style={{ background: appearance === 'error' ? '#CF5268' : '#249F6B' }}>
    {children}
  </div>
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
       <ToastProvider components={{ Toast: A11yToast }} placement='top-center'>
          <App />
        </ToastProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
