import userActions from './user';
import postActions from './posts';
import collectionsActions from './collections';
import searchActions from './search';
import onlineActions from './online';
import authActions from './authenticated';
import resetActions from './reset';

const actions = {
  userActions,
  postActions,
  collectionsActions,
  searchActions,
  onlineActions,
  authActions,
  resetActions
}

export default actions;