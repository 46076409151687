import React from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import actions from '../../redux/actions';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import TagManager from 'react-gtm-module';

const Callback = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();

  React.useEffect(() => {
    async function handleFederated() {
      try {
        const user = await Auth.currentAuthenticatedUser();

        const usr = {
          username: user.username,
          email: user.attributes.email,
        };

        //console.log('handle federated, user', JSON.stringify(user));

        // check and see if the user exists in the database using the email
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE}/user/getByEmailAndUsername/${user.attributes.email}/${user.username}`
        );
        console.log('res', res);
        if (res.data && res.data.length > 0 && res.data[0].country) {
          // user exists in the database
          console.log('user exists in the database');

          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE}/user`,
            usr
          );
          const userResponse = response.data;
          axios.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${user.signInUserSession.idToken.jwtToken}`;
          console.log(
            'setting user token in local storage',
            user.signInUserSession.idToken.jwtToken
          );
          localStorage.setItem(
            'authToken',
            user.signInUserSession.idToken.jwtToken
          );

          // send a gtm event for sign in.
          const tagManagerArgs = {
            gtmId: process.env.REACT_APP_GTM_ID,
            event: 'User Sign-in',
          };
          TagManager.initialize(tagManagerArgs);

          dispatch(actions.userActions.setUser(userResponse));
          dispatch(actions.authActions.setAuth(true));
          setTimeout(() => {
            history.push(`/`);
          }, 2000);
        } else {
          axios.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${user.signInUserSession.idToken.jwtToken}`;
          dispatch(actions.userActions.setUser(usr));
          setTimeout(() => {
            history.push(`/SignUp`);
          }, 300);
          localStorage.setItem(
            'authToken',
            user.signInUserSession.idToken.jwtToken
          );

          console.log('user does not exist in the database');
        }
      } catch (err) {
        addToast(`There was an error in the callback: ${err}`, {
          appearance: 'error',
        });
      }
    }
    handleFederated();
  }, [history, dispatch]);

  return (
    <div>
      <main aria-label="content" id="reach-skip-nav" className="container">
        <div className="row">
          <div className="col-12"></div>
        </div>
      </main>
    </div>
  );
};

export default Callback;
