import { store } from "./redux/store";
import actions from './redux/actions';

const registerOfflineListener = () => {
  window.addEventListener('online', handleConnection);
  window.addEventListener('offline', handleConnection);
  handleConnection();

  function handleConnection() {
    if (navigator.onLine) {
      isReachable('https://www.google.com').then(function(online) {
        if (online) {
          //console.log('online');
          store.dispatch(actions.onlineActions.setOnline(true));
        } else {
          //console.log('no connectivity');
          store.dispatch(actions.onlineActions.setOnline(false));
        }
      });
    } else {
      // handle offline status
      console.log('offline');
      store.dispatch(actions.onlineActions.setOnline(false));
    }
  }
  
  async function isReachable(url) {
    /**
     * Note: fetch() still "succeeds" for 404s on subdirectories,
     * which is ok when only testing for domain reachability.
     *
     * Example:
     *   https://google.com/noexist does not throw
     *   https://noexist.com/noexist does throw
     */
    try {
      const resp = await fetch(url, { method: 'HEAD', mode: 'no-cors' });
      return resp && (resp.ok || resp.type === 'opaque');
    } catch (err) {
      console.warn('[conn test failure]:', err);
    }
  }
}

export default registerOfflineListener;