import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import api from '../../data/api';
import { useToasts } from 'react-toast-notifications';

const InfoBanner = () => {
    const cookies = new Cookies();
    const [ firstTimeVisitor, setFirstTimeVisitor ] = useState(true);
    const location = useLocation();
    const currentLanguage = useSelector(state => state.user.currentLanguage);
    const [ sectionOne , setSectionOne ] = useState('');
    const [ sectionTwo , setSectionTwo ] = useState('');
    const [ sectionThree , setSectionThree ] = useState('');
    const [ sectionFour , setSectionFour ] = useState('');
    const [ sectionFive , setSectionFive ] = useState('');
    const [ sectionSix , setSectionSix ] = useState('');
    const { addToast } = useToasts();

    React.useEffect(() => {
      if(cookies.get("firstTime") === undefined) {
        //undefined means they didnt have the cookie so it is their first time -- so now set it
        cookies.set('firstTime', "false", { path: '/' });
    } else {
        setFirstTimeVisitor(false);
    }
    }, []);

    React.useEffect(() => {

      const changeLanguage = async () => {
        try {
          const sectionOneText = 'Log in to get the best experience, including personalized ideas and offline access. Not on FP insight yet?';
          const sectionTwoText = 'Unlock helpful tools and personalized ideas with a free account. It’s quick and easy!';
          const sectionThreeText = 'Log in or Sign up below';
          const sectionFourText = 'Sign up below.';
          const sectionFiveText = 'Sign up';
          const sectionSixText = 'now.';
          
          if (currentLanguage !== 'en') {


            const sections = await api.post(`${process.env.REACT_APP_API_BASE}/admin/translateText`, 
            {currentLanguage: currentLanguage, text: [sectionOneText, sectionTwoText, sectionThreeText, sectionFourText, sectionFiveText, sectionSixText], type: 'text', objectType: 'array'});
    
            if (sections.data && sections.data.length === 6) {
              setSectionOne(sections.data[0])
              setSectionTwo(sections.data[1]);
              setSectionThree(sections.data[2]);
              setSectionFour(sections.data[3]);
              setSectionFive(sections.data[4]);
              setSectionSix(sections.data[5]);
            } else {
              setSectionOne(sectionOneText)
              setSectionTwo(sectionTwoText);
              setSectionThree(sectionThreeText);
              setSectionFour(sectionFourText);
              setSectionFive(sectionFiveText);
              setSectionSix(sectionSixText);
            }
  
          } else {
            setSectionOne(sectionOneText)
            setSectionTwo(sectionTwoText);
            setSectionThree(sectionThreeText);
            setSectionFour(sectionFourText);
            setSectionFive(sectionFiveText);
            setSectionSix(sectionSixText);
  
          }
        } catch {
          addToast(`There was an error translating this page`, {appearance: 'error', autoDismiss: true, autoDismissTimeout: 10000});
        }
      }
      changeLanguage();
    }, [currentLanguage])



    
  return (
    <div className="infoBanner text-center">
        {
           firstTimeVisitor
            ?
            <p className="info-banner-message">{sectionTwo}&nbsp;
              { location.pathname === '/' ? sectionThree : <span><Link to='/?login=true'>Log in</Link> or <Link to='/'>{sectionFive}</Link> {sectionSix}</span> }
            </p>
            :
            <p className="info-banner-message">{sectionOne}&nbsp;
              { location.pathname === '/' ? sectionFour :  <span><Link to='/?login=false'>{sectionFive}</Link> {sectionSix}</span> }
            </p>
        }
    </div>
  )
}

export default InfoBanner;