import userActions from '../actions/user';
import resetActions from '../actions/reset';

const initialState = {
  user: null,
  a11yMessage: null,
  currentLanguage: 'en',
  languageSet: false
}

const userReducer = (state = initialState, action) => {
  switch(action.type) {
    case userActions.SET_USER: {
      const { user } = action.payload;
      return {
        ...state,
        user: user
      }
    }
    case userActions.MERGE_USER: {
      const { user } = action.payload;
      return {
        ...state,
        user: {...state.user, ...user}
      }
    }
    case userActions.ADD_USER_COLLECTION: {
      const { collection } = action.payload;
      if(state.user.collections) {
        return {
          ...state,
          user: {
            ...state.user, collections: [ ...state.user.collections, collection ]
          }
        }
      } else {
        return {
          ...state,
          user: {
            ...state.user, collections: [ collection ]
          }
        }
      }
    }
    case userActions.UPDATE_USER_COLLECTION: {
      const { collection } = action.payload;
      return {
        ...state,
        user: {
          ...state.user, collections: state.user.collections.map(c => c._id === collection._id ? collection : c)
        }
      }
    }
    case userActions.SET_MY_COLLECTION: {
      const collectionid = action.payload;
      return {
        ...state,
        user: {
          ...state.user, mycollection: collectionid
        }
      }
    }
    case userActions.SHOW_A11Y_MESSAGE: {
      const a11yMessage = action.payload;
      return {
        ...state,
        a11yMessage: a11yMessage
      }
    }
    case userActions.FOLLOW_COLLECTION: {
      const collectionId = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          followedCollections: [ ...state.user.followedCollections, collectionId]
        }
      }
    }
    case userActions.UNFOLLOW_COLLECTION: {
      const collectionId = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          followedCollections: state.user.followedCollections.filter(c => c !== collectionId)
        }
      }
    }
    case userActions.FOLLOW_USER: {
      const userId = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          followedUsers: [ ...state.user.followedUsers, userId ]
        }
      }
    }
    case userActions.UNFOLLOW_USER: {
      const userId = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          followedUsers: state.user.followedUsers.filter(u => u !== userId)
        }
      }
    }
    case userActions.SET_LANGUAGE: {
      const lang = action.payload;
      return {
        ...state,
        currentLanguage: lang,
        languageSet: true
      }
    }
    case resetActions.RESET_ALL:
      return initialState;
    default: {
      return state;
    }
  }
}

export default userReducer;