import collectionsActions from '../actions/collections';
import resetActions from '../actions/reset';

const initialState = {
  collections: [],
  currentCollection: null
}

const collectionsReducer = (state = initialState, action) => {
  switch(action.type) {
    case collectionsActions.SET_COLLECTIONS: {
      const collections = action.payload;
      return {
        ...state,
        collections: collections
      };
    }
    case collectionsActions.ADD_COLLECTION: {
      const collection = action.payload;
      return {
        ...state,
        collections: [...state.collections, collection]
      }
    }
    case collectionsActions.UPDATE_COLLECTION: {
      const collection = action.payload;
      return {
        ...state,
        collections: state.collections.map(c => c._id === collection._id ? collection : c)
      }
    }
    case collectionsActions.SET_CURRENT_COLLECTION: {
      const collectionId = action.payload;
      return {
        ...state,
        currentCollection: collectionId
      }
    }
    case resetActions.RESET_ALL:
      return initialState;
    default:
      return state;
  }
}

export default collectionsReducer;