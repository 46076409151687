const REPLACE_POSTS = 'REPLACE_POSTS';
const ADD_POST = 'ADD_POST';
const CHANGE_POST_TYPE = 'CHANGE_POST_TYPE';

const replacePosts = (posts) => ({
  type: REPLACE_POSTS,
  payload: posts
})

const addPost = (post) => ({
  type: ADD_POST,
  payload: post
})

const changePostType = type => ({
  type: CHANGE_POST_TYPE,
  payload: type
})

const exports = {
  REPLACE_POSTS,
  ADD_POST,
  CHANGE_POST_TYPE,
  replacePosts,
  addPost,
  changePostType
}

export default exports;