const SET_COLLECTIONS = 'SET_COLLECTIONS';
const ADD_COLLECTION = 'ADD_COLLECTION';
const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
const SET_CURRENT_COLLECTION = 'SET_CURRENT_COLLECTION';

const setCollections = (collections) => ({
  type: SET_COLLECTIONS,
  payload: collections
});

const addCollection = collection => ({
  type: ADD_COLLECTION,
  payload: collection
})

const updateCollection = collection => ({
  type: UPDATE_COLLECTION,
  payload: collection
})

const setCurrentCollection = collectionId => ({
  type: SET_CURRENT_COLLECTION,
  payload: collectionId
})

const exports = {
  SET_COLLECTIONS,
  ADD_COLLECTION,
  UPDATE_COLLECTION,
  SET_CURRENT_COLLECTION,
  setCollections,
  addCollection,
  updateCollection,
  setCurrentCollection
};

export default exports;