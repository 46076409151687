const SET_AUTH = 'SET_AUTH';

const setAuth = (auth) => ({
  type: SET_AUTH,
  payload: auth
})

const exports = {
  SET_AUTH,
  setAuth
};

export default exports;