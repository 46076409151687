import postActions from '../actions/posts';
import resetActions from '../actions/reset';

const initialState = {
  allPosts: [],
  type: ""
};

const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case postActions.ADD_POST: {
      const { post } = action.payload;
      return {
        ...state,
        allPosts: [...state.allPosts, post],
      };
    }
    case postActions.REPLACE_POSTS: {
      const { posts } = action.payload;
      return {
        ...state,
        allPosts: posts
      }
    }
    case postActions.CHANGE_POST_TYPE: {
      const type = action.payload;
      return {
        ...state,
        type: type
      }
    }
    case resetActions.RESET_ALL:
      return initialState;
    default: {
      return state;
    }
  }
}

export default postReducer;